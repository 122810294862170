<template>
  <header id="home" class="hero">
    <nav :class="['navbar-nav', { open: openMenu }]">
      <ul class="nav-links d-flex flex-column justify-center">
        <li>
          <a class="home-link" @click="handleNavigation('#home')"
            ><img src="@/assets/home-w.png" alt=""
          /></a>
        </li>
        <li><a @click="handleNavigation('#servicios')">Sevicios</a></li>
        <li><a @click="handleNavigation('#productos')">Productos</a></li>
        <li><a @click="handleNavigation('#nosotros')">Nosotros</a></li>
        <li>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <span dark v-bind="attrs" v-on="on">
                Línea de negocios
              </span>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>Herama Machinery</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Herama Food</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Herama Home</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Herama Liquor</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </li>
      </ul>
    </nav>
    <div class="hero-bg">
      <div class="hero-bg-left"></div>
      <div class="hero-bg-right align-self-end">
        <transition-group tag="div" name="slide" class="header-carousel">
          <div :key="slideSelected.id" class="carousel-item">
            <div class="slide-content">
              <div :class="`img-bg content-${slideSelected.id}`"></div>
              <div class="slide-text d-flex">
                <div class="slide-text-img">
                  <transition appear name="slide-2">
                    <picture class="small-hero-1">
                      <source :srcset="slideSelected.wepP" type="image/webp" />
                      <source :srcset="slideSelected.imgSrc" type="image/png" />
                      <img class="small-hero-1" :src="slideSelected.imgSrc" />
                    </picture>
                  </transition>
                  <transition appear name="slide-3">
                    <!-- eslint-disable-next-line -->
                    <p v-html="slideSelected.text"></p>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <v-container class="header-container pt-md-0">
      <div class="header-content  d-md-flex">
        <div class="main-header left d-flex flex-column">
          <!--Logo principal de Herama-->
          <div
            class="top-bar-res  d-flex justify-spape-between align-center pt-md-4"
          >
            <div class="logo-main">
              <img src="@/assets/logo-main.png" alt="Logo principal Herama" />
            </div>
            <v-spacer></v-spacer>
            <div
              class="links-social mobile d-flex justify-end align-center d-md-none"
            >
              <div class="social-link-wrapper">
                <a href="https://wa.me/50765950167" target="_blank"
                  ><img
                    class="d-block"
                    src="@/assets/logo-whatsapp-white.png"
                    alt="logo-email"
                /></a>
              </div>

              <div class="social-link-wrapper ">
                <a
                  href="https://www.instagram.com/heramaplastic/"
                  target="_blank"
                  ><img
                    class="d-block"
                    src="@/assets/ig-w.png"
                    alt="logo-instagram"
                /></a>
              </div>
            </div>

            <div
              :class="['menu-btn d-md-none', { open: openMenu }]"
              role="button"
              @click="handleMenu"
            >
              <div class="line line1"></div>
              <div class="line line2"></div>
              <div class="line line3"></div>
            </div>
          </div>
          <div class="main-text ">
            <!--Titulo principal-->
            <h1 class="header-title">
              <span class="title1">FÁBRICA</span>
              <span class="title2">DE ENVASES</span>
              <span class="title3">Y TAPAS PLÁSTICAS</span>
              <span class="title1 shadow">FÁBRICA</span>
            </h1>
            <h2 class="second-text-main">
              Soluciones para diversos <br />
              tipos de envasados con<br />
              <span class="last-line">estándares de calidad.</span>
            </h2>
            <div class="header-action d-flex justify-center">
              <div class="header-action-btn-wrapper mr-5 mr-sm-16">
                <v-btn
                  block
                  x-small
                  rounded
                  depressed
                  color="primary"
                  class="d-sm-none"
                  @click="dialog = true"
                  >¡Contáctanos!</v-btn
                >
                <v-btn
                  block
                  rounded
                  depressed
                  color="primary"
                  class="mr-5 d-none d-sm-inline-block"
                  @click="dialog = true"
                  >¡Contáctanos!</v-btn
                >
              </div>
              <div class="header-action-btn-wrapper ">
                <v-btn
                  class="header-action-btn d-sm-none"
                  x-small
                  block
                  rounded
                  depressed
                  color="primary"
                  @click="handleNavigation('#nosotros')"
                  >Más Info</v-btn
                >
                <v-btn
                  class="header-action-btn d-none d-sm-inline-block"
                  block
                  rounded
                  depressed
                  color="primary"
                  @click="handleNavigation('#nosotros')"
                  >Más Info</v-btn
                >
              </div>
            </div>
          </div>
        </div>
        <div class="right d-flex  flex-column ">
          <!--Barra de menu principal-->
          <div class="top-bar d-none d-md-flex flex-md-column flex-xl-row ">
            <div
              class="links-social order-1 d-flex justify-end align-center pa-lg-2"
            >
              <div class="social-link-wrapper pa-2 pa-lg-2">
                <a href="https://wa.me/50765950167" target="_blank"
                  ><img
                    class="d-block"
                    src="@/assets/logo-whatsapp.png"
                    alt="logo-email"
                /></a>
              </div>
              <!-- <div class="social-link-wrapper pa-2 pa-lg-2">
                <a href="#"
                  ><img
                    class="d-block"
                    src="@/assets/logo-facebook.png"
                    alt="logo-facebook"
                /></a>
              </div> -->
              <!-- <div class="social-link-wrapper pa-2 pa-lg-2">
                <a href="#"
                  ><img
                    class="d-block"
                    src="@/assets/logo-twitter.png"
                    alt="logo-twitter"
                /></a>
              </div> -->
              <div class="social-link-wrapper pa-2 pa-lg-2">
                <a
                  href="https://www.instagram.com/heramaplastic/"
                  target="_blank"
                  ><img
                    class="d-block"
                    src="@/assets/logo-instagram.png"
                    alt="logo-instagram"
                /></a>
              </div>
            </div>
            <nav
              class="nav-bar d-flex flex-column flex-md-row align-center order-2 order-xl-0"
            >
              <ul
                class="list-link d-md-flex justify-space-between justify-lg-space-around align-center"
              >
                <li class=" pr-3">
                  <a href="#"
                    ><img src="@/assets/home.png" alt="icono-home"
                  /></a>
                </li>
                <li class="active pl-3 pr-3">
                  <a @click="handleNavigation('#servicios')">Servicios</a>
                </li>
                <li class=" pl-3 pr-3">
                  <a @click="handleNavigation('#productos')">Productos</a>
                </li>
                <li class=" pl-3 pr-3">
                  <a @click="handleNavigation('#nosotros')">Nosotros</a>
                </li>
                <li class=" pl-3 pr-xl-6">
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="primary--text" dark v-bind="attrs" v-on="on">
                        Línea de negocios
                      </span>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>Herama Machinery</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Herama Food</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Herama Home</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Herama Liquor</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </li>
              </ul>
            </nav>
          </div>

          <!--Logo y contenidos textos-->

          <!--División de imagenes principales para el hero-->
          <!--Primer Slide-->
        </div>
      </div>
    </v-container>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay="false"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card class="pa-10">
        <v-card-title primary-title>
          <v-btn class="ml-auto" icon text @click="dialog = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <ContactForm modal @close-modal="dialog = false" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </header>
</template>

<script>
import ContactForm from './ContactForm'
export default {
  name: 'Hero',
  components: {
    ContactForm
  },
  data() {
    return {
      isScrolling: false,
      openMenu: false,
      index: 0,
      looper: null,
      dialog: false,
      slides: [
        {
          id: 1,
          imgSrc: require('@/assets/small-hero-1.png'),
          wepP: require('@/assets/small-hero-1.webp'),
          text: 'Impresiones 3D <br /> y prototipos reales.'
        },
        {
          id: 2,
          imgSrc: require('@/assets/small-hero-2.png'),
          wepP: require('@/assets/small-hero-2.webp'),
          text: 'Tapas plásticas en <br /> diferentes modelos <br />y colores.'
        },
        {
          id: 3,
          imgSrc: require('@/assets/small-hero-3.png'),
          wepP: require('@/assets/small-hero-3.webp'),
          text: 'Fabricación y <br />reparación de moldes.'
        },
        {
          id: 4,
          imgSrc: require('@/assets/small-hero-4.png'),
          webP: require('@/assets/small-hero-4.webp'),
          text: 'Procesos de inyección <br />y soplado.'
        }
      ]
    }
  },
  computed: {
    slideSelected() {
      return this.slides[this.index]
    }
  },
  watch: {
    openMenu(val) {
      if (val) {
        document.body.classList.add('menu')
      } else {
        document.body.classList.remove('menu')
      }
    },
    dialog(val) {
      if (val) {
        this.$recaptchaInstance.showBadge()
      } else {
        this.$recaptchaInstance.hideBadge()
      }
    }
  },
  mounted() {
    this.startSlider()
  },

  beforeDestroy() {
    clearInterval(this.looper)
  },

  methods: {
    handleNavigation(goTo) {
      this.openMenu = false
      const offset = goTo === '#nosotros' ? 60 : 40
      this.$vuetify.goTo(goTo, { offset })
    },
    handleMenu() {
      this.openMenu = !this.openMenu
    },
    startSlider() {
      this.looper = setInterval(() => {
        if (this.index === this.slides.length - 1) {
          this.index = 0
        } else {
          this.index++
        }
      }, 4000)
    }
  }
}
</script>

<style lang="scss">
.hero {
  height: 90vh;
  position: relative;
}
.navbar-nav {
  background-color: #0044bc;
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  height: 100vh;
  transform: translate(100%);
  transition: transform 250ms ease-out;
  z-index: 99;
  &.open {
    transform: translateX(0);
  }
  ul {
    list-style: none;
    text-align: right;
    padding-right: 1.25rem;
    font-size: 1.5rem;
    height: 100%;
    li {
      padding: 10px 0;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;

  .hero-bg-left {
    height: 50%;
    background-image: url('~@/assets/Fondoazul.png');
    background-position: center;
    background-size: cover;
  }
  .hero-bg-right {
    height: 50%;
  }
}
.top-bar-res {
  &.sticky {
    transition: all 250ms ease-out;
    position: fixed;
    width: calc(100% - 30px);
    height: 40px;
  }
}
.header-container {
  position: relative;
  height: 50%;
}
.header-content {
  height: 100%;
}

.left {
  height: 100%;
}

.home-link {
  img {
    width: 30px;
    height: auto;
  }
}
.menu-btn {
  flex: 0 0 15px;
  height: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  z-index: 9999;
  .line {
    height: 2px;
    width: 100%;
    border-radius: 99px;
    background: #fff;
    transition: all 250ms ease-out;
  }
  .line2 {
    transform-origin: center;
    transform: translateX(0);
    opacity: 1;
  }
  &.open {
    .line1 {
      transform-origin: center;
      transform: translateY(4px) rotate(45deg);
    }
    .line3 {
      transform-origin: center;
      transform: translateY(-5px) rotate(-45deg);
    }
    .line2 {
      transform: translateX(-100%);
      opacity: 0;
      visibility: collapse;
      pointer-events: none;
    }
  }
}

.logo-main {
  flex: 1 0 115px;
  width: 115px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  img {
    width: 100%;
    max-width: 115px;
    height: auto;
  }
}
.main-text {
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  flex: 1 1;
  margin: 0 auto;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 20px;
}

.header-title {
  position: relative;
  display: grid;
  grid-template-columns: minmax(32px, 6fr) 20fr;
  grid-template-rows: auto auto auto;
  z-index: 1;
}
.title1 {
  position: relative;
  font-size: min(max(2.4rem, 12vw), 6rem);
  @supports (font-size: clamp(3.1875rem, 12vw, 6rem)) {
    font-size: clamp(2.4rem, 12vw, 6rem);
  }
  color: #fff;
  grid-column: 1/3;
  grid-row: 1/2;
  z-index: 1;
  line-height: 0.8;

  &.shadow {
    position: relative;
    color: #0040bb;
    text-shadow: -1px -1px 0 rgba(#fff, 0.303), 1px -1px 0 rgba(#fff, 0.303),
      -1px 1px 0 rgba(#fff, 0.303), 1px 1px 0 rgba(#fff, 0.303);
    transform: translate(2%, -34%);
    z-index: 0;
    mix-blend-mode: overlay;
    opacity: 0.3;
  }
}

.title2 {
  font-size: min(max(1.5rem, 7vw), 3.5rem);
  @supports (font-size: clamp(1.2rem, 7vw, 3.7rem)) {
    font-size: clamp(1.5rem, 7vw, 3.5rem);
  }
  color: #00beff;
  mix-blend-mode: hard-light;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  position: relative;
  z-index: 2;
}
.title3 {
  font-size: min(max(1.2rem, 5.25vw), 3rem);
  @supports (font-size: clamp(1.7rem, 5.25vw, 3.5rem)) {
    font-size: clamp(1.2rem, 5.25vw, 3rem);
  }
  color: #fff;
  grid-column: 1 / 3;
  grid-row: 3 / 4;
}
.second-text-main {
  font-size: min(max(0.8rem, 3vw), 1.3rem);
  @supports (font-size: clamp(0.8rem, 3vw, 1.5rem)) {
    font-size: clamp(0.8rem, 3vw, 1.3rem);
  }
  line-height: 1;
  position: relative;
  padding-left: 1.5em;
  color: lighten($primary, $amount: 20);
  text-transform: none;
  display: inline-block;
  margin-bottom: 10px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 6px;
    height: 100%;
    background-color: lighten($primary, $amount: 10);
  }
  .last-line {
    &::after {
      content: '';
      position: absolute;
      left: 1.5em;
      bottom: -10px;
      display: block;
      width: 6em;
      height: 3px;
      background-color: #fff;
    }
  }
}
.mobile {
  margin-right: 15px;
}
.social-link-wrapper {
  margin-right: 5px;
  img {
    width: 25px;
    height: 25px;
  }
}
.list-link {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;

    &.active {
      background-color: $primary;
      a {
        color: #fff;
        margin: 0;
      }
    }
  }
  a {
    text-decoration: none;
    color: #333;
  }
}

.slide-content {
  width: 100%;
  height: 100%;
}
.img-bg {
  height: 70%;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.webp .content-1 {
  background-image: url('~@/assets/bg-hero-1.webp');
}
.webp .content-2 {
  background-image: url('~@/assets/bg-hero-2.webp');
}
.webp .content-3 {
  background-image: url('~@/assets/bg-hero-3.webp');
}
.webp .content-4 {
  background-image: url('~@/assets/bg-hero-4.webp');
}
.no-webp .content-1 {
  background-image: url('~@/assets/bg-hero-1.png');
}
.no-webp .content-2 {
  background-image: url('~@/assets/bg-hero-2.png');
}
.no-webp .content-3 {
  background-image: url('~@/assets/bg-hero-3.png');
}
.no-webp .content-4 {
  background-image: url('~@/assets/bg-hero-4.png');
}
.slide-text {
  height: 30%;
  color: #333;
  position: relative;

  .slide-text-img {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transform: translateX(-50%);
    img {
      margin-right: 15px;
      width: 150px;
      height: 150px;
      object-fit: cover;
      border: 8px solid #fff;
    }
    p {
      margin-bottom: 1em;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1;
    }
  }
}

.header-action-btn-wrapper {
  width: 77px;
}
.header-carousel {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.carousel-item {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 1s;
  transform: translateX(0);
}
.slide-enter /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(50%);
  opacity: 0;
}
.slide-leave-to {
  transform: translateX(-50%);
  opacity: 0;
}
.slide-2-enter-active,
.slide-2-leave-active {
  transition: all 1.3s 0.3s;
}
.slide-2-enter  /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(15%);
}
.slide-2-leave-to {
  opacity: 0;
  transform: translateX(-15%);
}
.slide-3-enter-active,
.slide-3-leave-active {
  transition: all 1.5s 0.5s;
}
.slide-3-enter  /* .fade-leave-active below version 3.1.8 */ {
  opacity: 0;
  transform: translateX(20%);
}
.slide-3-leave-to {
  opacity: 0;
  transform: translateX(-20%);
}

// Responsive

// SM
@media only screen and (min-width: 600px) {
  .logo-main {
    width: 220px;
  }
  .img-bg {
    height: 75%;
  }
  .slide-text {
    height: 25%;
  }
}
// MD
@media only screen and (min-width: 960px) {
  .hero {
    position: relative;
    height: 90vh;
    min-height: 600px;
    max-height: 900px;
  }
  .hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    .hero-bg-left {
      flex: 1 0 50%;
      height: 100%;
      background-image: url('~@/assets/Fondoazul.png');
      background-position: center;
      background-size: cover;
    }
    .hero-bg-right {
      flex: 1 0 50%;
      height: 80%;
    }
  }
  .header-container {
    position: relative;
    height: 90vh;
    min-height: 600px;
    max-height: 900px;
    z-index: 1;
  }
  .left {
    flex: 1 0 50%;
  }
  .right {
    flex: 1 0 50%;
    position: relative;
  }
  .logo-main {
    flex: 1 0 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    img {
      width: 100%;
      max-width: 274px;
      height: auto;
    }
  }
  .main-text {
    flex: 1 0 80%;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1;
  }
  .header-title {
    position: relative;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: minmax(67px, 15%) 1fr;
    grid-template-rows: auto auto auto;
    z-index: 1;
    margin: 0;
  }
  .title1 {
    position: relative;
    font-size: min(max(3rem, 8vw), 5.5rem);
    @supports (font-size: clamp(3rem, 8vw, 5.5rem)) {
      font-size: clamp(3rem, 8vw, 5.5rem);
    }
    color: #fff;
    grid-column: 1/3;
    grid-row: 1/2;
    z-index: 1;
    &.shadow {
      position: relative;
      color: #0040bb;
      text-shadow: -1px -1px 0 rgba(#fff, 0.303), 1px -1px 0 rgba(#fff, 0.303),
        -1px 1px 0 rgba(#fff, 0.303), 1px 1px 0 rgba(#fff, 0.303);
      transform: translate(3%, -24%);
      z-index: 0;
      mix-blend-mode: overlay;
      opacity: 0.3;
    }
  }

  .title2 {
    font-size: min(max(2rem, 5vw), 3.4rem);
    @supports (font-size: clamp(2rem, 5vw, 3.4rem)) {
      font-size: clamp(2rem, 5vw, 3.4rem);
    }
    color: #00beff;
    mix-blend-mode: hard-light;
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    position: relative;
    z-index: 2;
  }
  .title3 {
    font-size: min(max(2rem, 7vw), 2.8rem);
    @supports (font-size: clamp(2rem, 7vw, 2.8rem)) {
      font-size: clamp(2rem, 7vw, 2.8rem);
    }
    color: #fff;
    grid-column: 1 / 4;
    grid-row: 3 / 4;
  }
  .second-text-main {
    font-size: min(max(1.5rem, 1.5vw), 2rem);
    @supports (font-size: clamp(1.5rem, 1.5vw, 2rem)) {
      font-size: clamp(1.5rem, 1.5vw, 2rem);
    }
    line-height: 1.4;
    position: relative;
    padding-left: 2em;
    color: lighten($primary, $amount: 20);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 10px;
      height: 100%;
      background-color: lighten($primary, $amount: 10);
    }
    .last-line {
      &::after {
        content: '';
        position: absolute;
        left: 3em;
        bottom: -30px;
        display: block;
        width: 6em;
        height: 6px;
        background-color: #fff;
      }
    }
  }
  .top-bar {
    height: 20%;
    width: 100%;
  }
  .links-social {
    flex: 0 0 40px;
  }
  .nav-bar {
    flex: 1 0;
    position: relative;
    height: auto;
  }
  .social-link-wrapper {
    img {
      width: 30px;
      height: 30px;
    }
  }
  .list-link {
    width: 100%;
    height: 100%;
    li {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;

      &.active {
        background-color: $primary;
        a {
          color: #fff;
          margin: 0;
        }
      }
    }
    a {
      text-decoration: none;
      color: #333;
      margin-right: 5px;
    }
  }
  .header-action-btn-wrapper {
    width: 180px;
  }
  .img-bg {
    height: 80%;
  }
  .slide-text {
    height: 20%;
    .slide-text-img {
      left: 50%;
      transform: translateX(-50%);
      img {
        margin-right: 20px;
        width: 250px;
        height: 250px;
        object-fit: cover;
        border: 8px solid #fff;
      }
      p {
        font-size: 1.5rem;
        margin-bottom: 1.5em;
      }
    }
  }
}
// LG
@media only screen and (min-width: 1264px) {
  // .slide-text {
  //   .slide-text-img {
  //     left: 150px;
  //   }
  // }
}
// XL
@media only screen and (min-width: 1904px) {
  .header-title {
    grid-template-columns: minmax(67px, 22%) 1fr;
    grid-template-rows: auto auto auto;
  }
  .title1 {
    font-size: min(max(3rem, 9vw), 10rem);
    @supports (font-size: clamp(3rem, 9vw, 10rem)) {
      font-size: clamp(3rem, 9vw, 10rem);
    }
  }
  .title2 {
    font-size: min(max(2rem, 9vw), 5.7rem);
    @supports (font-size: clamp(2rem, 9vw, 6.0625rem)) {
      font-size: clamp(2rem, 9vw, 5.7);
    }
  }
  .title3 {
    font-size: min(max(2rem, 9vw), 4.625rem);
    @supports (font-size: clamp(2rem, 9vw, 4.625rem)) {
      font-size: clamp(2rem, 9vw, 4.625rem);
    }
  }
  // .slide-text {
  //   .slide-text-img {
  //     left: 250px;
  //   }
  // }
}
</style>
