<template>
  <section class="porque-trabajar-nosotros py-8 py-md-16 px-5 ">
    <v-container class="">
      <h2
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0, 0.5, 1.0]
          }
        }"
        class="title-trabaja-nosotros center-up mb-16"
      >
        ¿Por qué trabajar con nosotros?
      </h2>
      <v-row>
        <v-col cols="12" md="4">
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            class="experiencia left-up"
          >
            <v-row justify="center">
              <v-col cols="3" md="12">
                <div class="img-wrapper">
                  <img
                    class="icon-trabaja-nosotros"
                    src="@/assets/experiencia-icon.png"
                    alt="Icono descripción de experiencia"
                  />
                </div>
              </v-col>
              <v-col cols="9" md="12">
                <div class="content px-4 text-md-center">
                  <h3 class="title mb-3">Experiencia</h3>
                  <p class="description-text">
                    Hablar de más de <strong>20 años</strong> de experiencia en
                    el mercado nos otorga la
                    <strong>credibilidad</strong> necesaria para ofrecerle la
                    mejor <strong>confiabilidad</strong>
                    en nuestra gestión de servicio.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            class="profesionalismo center-up"
          >
            <v-row>
              <v-col cols="3" md="12">
                <div class="img-wrapper">
                  <img
                    class="icon-trabaja-nosotros"
                    src="@/assets/profesionalismo-icon.png"
                    alt="Icono descripción de profesionalismo"
                  />
                </div>
              </v-col>
              <v-col cols="9" md="12">
                <div class="content px-4 text-md-center">
                  <h3 class="title">Profesionalismo</h3>
                  <p class="description-text">
                    Hacer las cosas de manera correcta y conocer a cabalidad
                    nuestro <strong>desempeño</strong> nos ha potenciado para
                    exigirnos a nosotros mismos en la preparación y manufactura
                    de nuestros productos.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div></v-col
        >
        <v-col cols="12" md="4">
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            class="calidad right-up"
          >
            <v-row>
              <v-col cols="3" md="12">
                <div class="img-wrapper">
                  <img
                    class="icon-trabaja-nosotros"
                    src="@/assets/calidad-icon.png"
                    alt="Icono descripción de Calidad"
                  />
                </div>
              </v-col>
              <v-col cols="9" md="12">
                <div class="content px-4 text-md-center">
                  <h3 class="calidad-title">Calidad</h3>
                  <p class="description-text">
                    Es un valor presente en todos nuestros procesos de
                    fabricación para <strong>garantizarle</strong> que usted
                    reciba la mejor atención y el mejor producto.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div></v-col
        >
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'TrabajaNosotros',
  props: {
    onIntersect: Function
  }
}
</script>

<style lang="scss" scoped>
.porque-trabajar-nosotros {
  background-image: url('~@/assets/bg-trabaja-nosotros.png');
  background-position: center;
  background-size: cover;
  color: #fff;

  .title-trabaja-nosotros {
    font-size: min(max(1rem, 4vw), 2rem);
    @supports (font-size: clamp(1rem, 4vw, 2rem)) {
      font-size: clamp(1rem, 4vw, 2rem);
    }
    color: #fff;
    text-align: center;
    position: relative;

    &::after {
      content: '';
      display: block;
      background-color: #fff;
      position: absolute;
      bottom: -0.5em;
      width: calc(5vw + 100px);
      height: 4px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .img-wrapper {
    display: flex;
    justify-content: center;
    height: 100px;
    img {
      width: 100%;
      max-width: 60px;
      height: 100%;
      object-fit: contain;
    }
  }
  .description-text {
    font-weight: 300;
    font-size: min(max(0.8rem, 4vw), 1.25rem);
    @supports (font-size: clamp(0.8rem, 4vw, 1.25rem)) {
      font-size: clamp(0.8rem, 4vw, 1.25rem);
    }
  }
  .title {
    font-weight: 700;
    font-size: min(max(1rem, 4vw), 1.55rem);
    @supports (font-size: clamp(1rem, 4vw, 1.55rem)) {
      font-size: clamp(1rem, 4vw, 1.55rem);
    }
  }
}
</style>
