<template>
  <v-app v-scroll="onScroll" id="app">
    <v-main :class="['main-layer', { 'show-menu': showScrollingMenu }]">
      <ScrollingMenu
        :open-menu-external="openMenu"
        :show="showScrollingMenu"
        :active="sectionActive"
        :links="links"
        @menu-closed="openMenu = false"
      />
      <Hero @open-menu="handleOpenMenu" />
      <Servicios :onIntersect="onIntersect" />
      <TrabajaNosotros :onIntersect="onIntersect" />
      <Productos :onIntersect="onIntersect" />
      <Nosotros :onIntersect="onIntersect" />
      <MyFooter :onIntersect="onIntersect" />
    </v-main>
  </v-app>
</template>
<script>
import Hero from '@/components/Hero'
import TrabajaNosotros from '@/components/TrabajaNosotros'
import Nosotros from '@/components/Nosotros'
import Productos from '@/components/Productos'
import Servicios from '@/components/Servicios'
import MyFooter from '@/components/MyFooter'
import ScrollingMenu from '@/components/ScrollingMenu'
export default {
  name: 'App',
  components: {
    Hero,
    Nosotros,
    TrabajaNosotros,
    Productos,
    Servicios,
    ScrollingMenu,
    MyFooter
  },
  data() {
    return {
      showScrollingMenu: false,
      openMenu: false,
      sectionActive: '',
      links: [
        { title: 'home', active: false, img: require('@/assets/home-w.png') },
        { title: 'servicios', active: false },
        { title: 'productos', active: false },
        { title: 'nosotros', active: false }
      ]
    }
  },
  mounted() {},
  methods: {
    onIntersect(entries) {
      const el = entries[0].target
      if (entries[0].intersectionRatio >= 0.5) {
        el.classList.add('show')
      } else {
        el.classList.remove('show')
      }
    },
    handleOpenMenu(val) {
      // eslint-disable-next-line
      console.log('val: ', val)

      this.openMenu = val
    },
    onScroll() {
      for (let i = 0; i < this.links.length; i++) {
        const section = this.links[i].title
        const el = document.getElementById(section)
        const offsetTop = el.offsetTop
        const offsetHeight = el.offsetHeight

        if (
          window.pageYOffset >= offsetTop - 100 &&
          window.pageYOffset < offsetTop + offsetHeight - 100
        ) {
          this.links[i].active = true
        } else {
          this.links[i].active = false
        }
      }
      if (window.pageYOffset > 40) {
        this.showScrollingMenu = true
      } else {
        this.showScrollingMenu = false
      }
    }
  }
}
</script>

<style lang="scss">
body {
  &.menu {
    position: fixed;
    width: 100vw;
    height: 100%;
  }
}

.main-layer {
  position: relative;
  overflow: hidden;
  z-index: 1;
  &.show-menu {
    padding-top: 59px;
  }
}
// Animations only in md size

@media (min-width: 769px) {
  .left-up {
    transform: translate(-50px, 50px) scale(0.9);
    transition: all 600ms ease-out;
    opacity: 0;
  }
  .right-up {
    transform: translate(50px, 50px) scale(0.9);
    transition: all 600ms ease-out;
    opacity: 0;
  }
  .center-up {
    transform: translate(0, 50px) scale(0.9);
    transition: all 600ms ease-out;
    opacity: 0;
  }
  .fade {
    transition: all 700ms ease-out;
    opacity: 0;
  }
  .slide-left {
    transform: translate(-50px, 0) scale(1);
    opacity: 0;
    transition: all 600ms ease-out;
  }
  .slide-right {
    transform: translate(50px, 0) scale(1);
    opacity: 0;
    transition: all 600ms ease-out;
  }
}

.show {
  transform: translate(0, 0) scale(1);
  opacity: 1;
}
</style>
