<template>
  <section id="productos" class="products py-8 py-md-16 px-5">
    <v-container class="">
      <div class="title-section-wrapper text-center mb-10">
        <h2
          v-intersect="{
            handler: onIntersect,
            options: {
              threshold: [0, 0.5, 1.0]
            }
          }"
          class="title-section text-center center-up"
        >
          Productos
        </h2>
      </div>
      <div class="productos-desc mb-8 mb-md-16">
        <p
          v-intersect="{
            handler: onIntersect,
            options: {
              threshold: [0, 0.5, 1.0]
            }
          }"
          class="description-section text-center fade"
        >
          Nuestra gama de productos ofrece soluciones para diversos tipos de
          envasados. Fabricados en materiales adecuados para cada suministro.
          Proveemos tamaños modelos y colores que se ajustan a la necesidad de
          sus requerimientos.
        </p>
      </div>
      <div
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0, 0.5, 1.0]
          }
        }"
        class="productos-carousel-wrapper d-md-none center-up"
      >
        <div class="flechas flecha-right">
          <img src="@/assets/chevron-right.png" alt="" />
        </div>
        <div class="flechas flecha-left">
          <img src="@/assets/chevron-left.png" alt="" />
        </div>
        <div class="productos-carousel-inner d-flex flex-no-wrap">
          <div
            class="productos-carousel-item"
            v-for="producto in finalListProducts"
            :key="producto.id"
          >
            <div class="product-card text-center">
              <div class="img-wrapper mb-4">
                <picture class="product-img">
                  <source :srcset="producto.imgUrl.mdWebp" type="image/webp" />
                  <source :srcset="producto.imgUrl.md" type="image/png" />
                  <img
                    :src="producto.imgUrl.md"
                    :alt="producto.title.toUpperCase()"
                  />
                </picture>
              </div>
              <h3 class="product-title mb-4">
                {{ producto.title.toUpperCase() }}
              </h3>
              <div class="product-list-description-wrapper text-left">
                <table class="product-list-description">
                  <tbody>
                    <tr v-if="producto.data.material">
                      <th>
                        <strong>Material:</strong>
                      </th>
                      <td class="pl-3">{{ producto.data.material }}</td>
                    </tr>
                    <tr v-if="producto.data.boca">
                      <th>
                        <strong>Boca:</strong>
                      </th>
                      <td class="pl-3">{{ producto.data.boca }}</td>
                    </tr>
                    <tr v-if="producto.data.tapa">
                      <th>
                        <strong>Tapa:</strong>
                      </th>
                      <td class="pl-3">{{ producto.data.tapa }}</td>
                    </tr>
                    <tr v-if="producto.data.capacidad">
                      <th>
                        <strong>Capacidad:</strong>
                      </th>
                      <td class="pl-3">{{ producto.data.capacidad }}</td>
                    </tr>
                    <tr v-if="producto.data.gramaje">
                      <th>
                        <strong>Gramaje:</strong>
                      </th>
                      <td class="pl-3">{{ producto.data.gramaje }}</td>
                    </tr>
                    <tr v-if="producto.data.color">
                      <th>
                        <strong>Color:</strong>
                      </th>
                      <td class="pl-3">{{ producto.data.color }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-row class="md d-none d-md-flex">
        <v-col
          v-for="(producto, i) in finalListProducts"
          :key="producto.id"
          cols="12"
          sm="6"
          md="3"
          lg="3"
        >
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            :class="[
              'product-card text-center',
              { 'slide-left': i % 2 === 0, 'slide-right': i % 2 !== 0 }
            ]"
          >
            <div class="img-wrapper mb-4">
              <picture
                class="product-img"
                @click="handleSelectProduct(producto.id)"
              >
                <source :srcset="producto.imgUrl.mdWebp" type="image/webp" />
                <source :srcset="producto.imgUrl.md" type="image/png" />
                <img
                  :src="producto.imgUrl.md"
                  :alt="producto.title.toUpperCase()"
                />
              </picture>
            </div>
            <h3
              class="product-title mb-4"
              @click="handleSelectProduct(producto.id)"
            >
              {{ producto.title.toUpperCase() }}
            </h3>
          </div>
        </v-col>
        <v-col cols="6" md="3" lg="2">
          <div class="btn-product">
            <img
              class="btn-product-arrow"
              src="@/assets/btn-product.png"
              alt=""
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="showProduct"
      :overlay="false"
      max-width="600px"
      transition="dialog-transition"
    >
      <div v-if="showProduct" ref="cardWrapper" class="card-wrapper">
        <button class="close-btn" @click="showProduct = false">X</button>
        <transition :name="transition">
          <div ref="productCard" class="product-card " :key="selected.id">
            <div class="img-wrapper mb-4">
              <picture class="product-img">
                <source :srcset="selected.imgUrl.mainWebp" type="image/webp" />
                <source :srcset="selected.imgUrl.main" type="image/png" />
                <img
                  :src="selected.imgUrl.md"
                  :alt="selected.title.toUpperCase()"
                />
              </picture>
            </div>
            <h3 class="product-title mb-4 text-center">
              {{ selected.title.toUpperCase() }}
            </h3>
            <div
              class="product-list-description-wrapper d-flex justify-center align-center"
            >
              <table class="product-list-description">
                <tbody>
                  <tr v-for="(value, name) in selected.data" :key="name">
                    <th class="th-vertical">
                      <strong
                        >{{
                          name.charAt(0).toUpperCase() + name.slice(1)
                        }}:</strong
                      >
                    </th>
                    <td class="pl-3">{{ value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </transition>
        <div
          class="card-actions pa-2 py-md-4 d-flex justify-space-between align-center"
        >
          <v-btn v-if="!atEnd" icon @click="prev"
            ><img class="icon-btn" src="@/assets/chevron-left.png" alt=""
          /></v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!atStart" icon @click="next"
            ><img class="icon-btn" src="@/assets/chevron-right.png" alt=""
          /></v-btn>
        </div>
      </div>
    </v-dialog>
  </section>
</template>

<script>
import { db } from '@/main'
export default {
  name: 'Productos',
  props: {
    onIntersect: Function
  },
  data() {
    return {
      productos: [],
      selectedId: null,
      showProduct: false,
      transition: 'slide-next'
    }
  },
  computed: {
    formattedProducts() {
      return this.productos.map(p => {
        const { id, title, imgUrl, ...data } = p

        return {
          id,
          title,
          imgUrl,
          data
        }
      })
    },
    productosPet() {
      return this.formattedProducts.filter(p => p.data.material === 'PET')
    },
    productosPP() {
      return this.formattedProducts.filter(p => p.data.material === 'PP')
    },
    productosPead() {
      return this.formattedProducts.filter(p => p.data.material === 'Pead')
    },
    finalListProducts() {
      return [...this.productosPead, ...this.productosPet, ...this.productosPP]
    },
    selected() {
      return (
        this.formattedProducts.filter(p => p.id === this.selectedId)[0] || null
      )
    },
    currentIndex() {
      return this.finalListProducts.map(p => p.id).indexOf(this.selectedId)
    },
    atEnd() {
      return this.currentIndex === 0
    },
    atStart() {
      return this.currentIndex === this.finalListProducts.length - 1
    }
  },
  mounted() {
    if (!this.productos.lenght) {
      db.collection('productos')
        .get()
        .then(snapshoot => {
          snapshoot.forEach(doc => {
            this.productos.push({
              ...doc.data(),
              id: doc.id
            })
          })
        })
    }
  },
  methods: {
    handleSelectProduct(productoId) {
      this.selectedId = productoId
      this.showProduct = true
    },
    prev() {
      const cardWrapper = this.$refs.cardWrapper
      const productCard = this.$refs.productCard
      const kHeight = cardWrapper.clientHeight - productCard.clientHeight
      const oldHeight = cardWrapper.clientHeight
      // eslint-disable-next-line
      console.log('oldHeight: ', oldHeight)

      productCard.style.position = 'absolute'
      cardWrapper.style.height = `${oldHeight}px`

      if (this.currentIndex > 0) {
        this.transition = 'slide-prev'
        this.selectedId = this.finalListProducts[this.currentIndex - 1].id
      }
      setTimeout(() => {
        const newHeight = this.$refs.productCard.clientHeight + kHeight
        // eslint-disable-next-line
        console.log('newHeight: ', newHeight)
        cardWrapper.style.height = `${newHeight}px`
      }, 10)
    },
    next() {
      const cardWrapper = this.$refs.cardWrapper
      const productCard = this.$refs.productCard
      const kHeight = cardWrapper.clientHeight - productCard.clientHeight
      const oldHeight = cardWrapper.clientHeight
      // eslint-disable-next-line
      console.log('oldHeight: ', oldHeight)

      this.$refs.productCard.style.position = 'absolute'
      cardWrapper.style.height = `${oldHeight}px`

      if (this.currentIndex < this.finalListProducts.length - 1) {
        this.transition = 'slide-next'

        this.selectedId = this.finalListProducts[this.currentIndex + 1].id
      }

      setTimeout(() => {
        const newHeight = this.$refs.productCard.clientHeight + kHeight
        // eslint-disable-next-line
        console.log('newHeight: ', newHeight)
        cardWrapper.style.height = `${newHeight}px`
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
  .productos-desc {
    max-width: 885px;
    margin: 0 auto;
    font-size: min(max(0.8rem, 4vw), 1.5rem);
    @supports (font-size: clamp(0.8rem, 4vw, 1.5rem)) {
      font-size: clamp(0.8rem, 4vw, 1.5rem);
    }
    font-weight: 300;
  }
  .img-wrapper {
    width: 100%;
    height: 200px;
    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
      text-align: center;
      line-height: 100%;
    }
  }

  .product-card {
    color: #333;
    transition: all 250ms ease-out;
  }
  .product-title {
    color: #005eb1;
  }
  .product-list-description-wrapper {
    max-width: 300px;
    margin: 0 auto;
  }
  .product-list-description {
    width: 100%;
    th {
      text-align: right;
    }
    td {
      width: 50%;
    }
  }
  .productos-carousel-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .flechas {
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    z-index: 2;
    opacity: 0.4;
    width: 15px;
    height: 15px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .flecha-left {
    left: 0px;
  }
  .flecha-right {
    right: 0px;
  }

  .productos-carousel-inner {
    scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    position: relative;
    overflow-y: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .productos-carousel-item {
    scroll-snap-align: center;
    flex: 1 0 100%;
  }
}
.md {
  .img-wrapper,
  .product-title {
    cursor: pointer;
  }
}
.card-wrapper {
  max-width: 600px;
  overflow: hidden;
  background: #fff;

  position: relative;
  transition: all 200ms ease-out;
  .close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
  }
  .product-card {
    padding: 30px 20px;
    width: 100%;
    transition: all 200ms ease-out;
  }

  .th-vertical {
    text-align: right;
    width: 50%;
  }
  .icon-btn {
    max-width: 10px;
  }
  .img-wrapper {
    max-width: 400px;
    max-height: 400px;
    margin: 0 auto;
    .product-img,
    img {
      width: 100%;
      height: 400px;
      object-fit: contain;
      text-align: center;
      line-height: 100%;
    }
  }
  .product-title {
    color: #005eb1;
  }
  .product-list-description-wrapper {
    max-width: 400px;
    margin: 0 auto;
  }
}
.slide-next-enter-active,
.slide-next-leave-active {
  transition: all 0.5s;
}
.slide-next-enter /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
  max-height: 100%;
  opacity: 0;
}
.slide-next-leave-to {
  transform: translateX(-100%);
  max-height: 100%;
  opacity: 0;
}
.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: all 0.5s;
}
.slide-prev-enter /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100%);
  max-height: 100%;
  opacity: 0;
}
.slide-prev-leave-to {
  transform: translateX(100%);
  max-height: 100%;
  opacity: 0;
}

// Responsive

// SM
@media only screen and (min-width: 600px) {
  .products {
    .carousel-item {
      flex: 1 0 50%;
    }
  }
}
// MD
@media only screen and (min-width: 960px) {
}
// LG
@media only screen and (min-width: 1264px) {
}
// XL
@media only screen and (min-width: 1904px) {
}
</style>
