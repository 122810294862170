<template>
  <section id="nosotros" class="nosotros">
    <div class="title-wrapper pa-8 mb-md-12">
      <h2
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0, 0.5, 1.0]
          }
        }"
        class="title-nosotros fade"
      >
        Nosotros
      </h2>
    </div>
    <v-carousel
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: [0, 0.5, 1.0]
        }
      }"
      hide-delimiters
      cycle
      :show-arrows="false"
      height="260px"
      class="d-md-none slide-left"
    >
      <v-carousel-item>
        <img
          class="img-nosotros d-block"
          src="@/assets/nosotros-envases.png"
          alt="Envases de colores"
        />
      </v-carousel-item>

      <v-carousel-item>
        <img
          class="img-nosotros d-block"
          src="@/assets/bg-hero-2.png"
          alt="Envases de colores"
        />
      </v-carousel-item>
      <v-carousel-item>
        <img
          class="img-nosotros d-block"
          src="@/assets/nosotros-2.png"
          alt="Envases de colores"
        />
      </v-carousel-item>
    </v-carousel>
    <v-container class="nosotros-container">
      <v-row justify="center">
        <v-col cols="12" md="6" lg="5" class="pr-8 mb-8">
          <v-carousel
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            hide-delimiters
            cycle
            :show-arrows="false"
            height="400px"
            class="d-none d-md-block slide-left"
          >
            <v-carousel-item>
            <picture class="img-nosotros d-block">
                      <source srcset="@/assets/nosotros-envases.webp" type="image/webp" />
                      <source srcset="@/assets/nosotros-envases.png" type="image/png" />
                      <img
                class="img-nosotros d-block"
                src="@/assets/nosotros-envases.png"
                alt="Envases de colores"
              />
                    </picture>
              
            </v-carousel-item>

            <v-carousel-item>
            <picture class="img-nosotros d-block">
                      <source srcset="@/assets/bg-hero-2.webp" type="image/webp" />
                      <source srcset="@/assets/bg-hero-2.png" type="image/png" />
                     <img
                class="img-nosotros d-block"
                src="@/assets/bg-hero-2.png"
                alt="Envases de colores"
              />
                    </picture>
              
            </v-carousel-item>
            <v-carousel-item>
            <picture class="img-nosotros d-block">
                    <source srcset="@/assets/nosotros-2-2.webp" type="image/webp" />
                      <source srcset="@/assets/nosotros-2-2.png" type="image/png" />
                     <img
                class="img-nosotros d-block"
                src="@/assets/nosotros-2-2.png"
                alt="Envases de colores"
              />
                    </picture>
             
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <!--Logo y experiencia de la empresa herama-->
        <v-col cols="12" md="6" lg="5" class="pr-8 pl-8 mb-8">
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            class="text-about-us slide-right"
          >
            <img
              width="343px"
              class="logo-nosotros d-block mb-10"
              src="@/assets/logo-nosotros.png"
              alt="logo de Herama Group"
            />
            <h3 class="subtitle-description mb-4">
              Más de 20 Años de experiencia.
            </h3>
            <p class="text-about">
              Somos una empresa permanentemente renovada en cuanto a su
              infraestructura y capacidad instalada, así como por la
              capacitación firme y constante a nuestro personal, para
              proporcionar una excelente atención y asesoría a nuestros
              clientes.
            </p>
          </div>
        </v-col>
        <!--Mision y vision de la empresa-->
        <v-col cols="12" md="6" lg="5" class="pr-8 pl-8 mb-8">
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            class="mision-description slide-left"
          >
            <h3 class="title-mision mb-4">Nuestra misión</h3>
            <p class="text-about">
              Distribuir eficaz y eficientemente envases para satisfacer las
              necesidades actuales y futuras del mercado, apoyando a los
              clientes en la búsqueda del éxito de sus productos. A su vez
              apoyando el sector industrial con el servicio de asesoría y
              provisión de la maquinaria necesaria para iniciar o repotenciar su
              empresa en el sector plástico.
            </p>
          </div></v-col
        >
        <v-col cols="12" md="6" lg="5" class=" pr-8 pl-8 mb-8">
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            class="vision-description slide-right"
          >
            <h3 class="title-mision mb-4">Nuestra visión</h3>
            <p class="text-about">
              Herama es una empresa dedicada a servir a los mercados nacionales
              y latinoamericanos, respetuosa y comprometida con sus clientes,
              empleados y la comunidad. Llevándoles productos de alta eficiencia
              en pro del crecimiento industrial de los países.
            </p>
          </div></v-col
        >
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Nosotros',
  props: {
    onIntersect: Function
  }
}
</script>

<style lang="scss" scoped>
.nosotros {
  .nosotros-container {
    max-width: 1400px;
  }
  .title-wrapper {
    background-color: #004570;
  }
  .title-nosotros {
    font-size: min(max(1.25rem, 4vw), 2rem);
    @supports (font-size: clamp(1.25rem, 4vw, 2rem)) {
      font-size: clamp(1.25rem, 4vw, 2rem);
    }
    text-align: center;
    color: #fff;
  }
  .logo-nosotros {
    max-width: 100%;
  }
  .img-nosotros {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
