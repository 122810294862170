<template>
  <section id="servicios" class="servicios">
    <div
      class="seccion-title-wrapper d-flex  justify-center align-center mb-5 mb-md-10 mb-lg-16"
    >
      <h2 class="title-section">SERVICIOS</h2>
    </div>

    <v-container class="">
      <v-row justify="center">
        <v-col cols="12" md="6" xl="5">
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            class="envases-service left-up d-md-flex mb-5 mb-md-10 mb-lg-15"
          >
            <div class="service-item-img-wrapper d-none d-md-block">
              <picture class="img-service">
                <source
                  srcset="@/assets/envases-service.webp"
                  type="image/webp"
                />
                <source
                  srcset="@/assets/envases-service.png"
                  type="image/png"
                />
                <img
                  class="img-service"
                  src="@/assets/envases-service.png"
                  alt="Diseño de Envases plásticos"
                />
              </picture>
            </div>

            <div class="service-item-content px-5">
              <h3 class="title-service mb-3 d-none d-md-block">
                DISEÑO DE ENVASES
              </h3>
              <h4 class="subtitle-service mb-3 d-none d-md-block">
                Asesoría, diseño y desarrollo de prototipos y productos
                plásticos e impresión 3D.
              </h4>
              <div :class="['desc pb-3', { 'show-more': showMore === 1 }]">
                <picture class="img-service d-md-none">
                  <source
                    srcset="@/assets/envases-service.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="@/assets/envases-service.png"
                    type="image/png"
                  />
                  <img
                    class="img-service d-md-none"
                    src="@/assets/envases-service.png"
                    alt="Diseño de Envases plásticos"
                  />
                </picture>

                <h3 class="title-service mb-3 d-md-none">
                  DISEÑO DE ENVASES
                </h3>
                <h4 class="subtitle-service mb-3 d-md-none">
                  Asesoría, diseño y desarrollo de prototipos y productos
                  plásticos e impresión 3D.
                </h4>
                <p class="text-service">
                  Ofrecemos asesoría profesional en el diseño de todo tipo de
                  envases para la industria nacional y extranjera.
                </p>
                <p>
                  Nuestra larga experiencia en el mercado de envases plásticos
                  nos permite ofrecer a nuestros clientes la posibilidad de
                  crear un diseño personalizado, impresiones 3D y prototipos
                  reales que permita obtener un envase que cumpla con las
                  expectativas de nuestros clientes y sus marcas. Asesoría,
                  diseño y desarrollo de prototipos y productos plásticos e
                  impresión 3D. Ofrecemos asesoría profesional en el diseño de
                  todo tipo de envases para la industria nacional y extranjera.
                  Nuestra larga experiencia en el mercado de envases plásticos
                  nos permite ofrecer a nuestros clientes la posibilidad de
                  crear un diseño personalizado, impresiones 3D y prototipos
                  reales que permita obtener un envase que cumpla con las
                  expectativas de nuestros clientes y sus marcas.
                </p>
              </div>
              <!--Boton de "leer mas" Envases-->
              <v-btn
                text
                class="btn-read-more d-none d-md-inline-block"
                @click="showMore !== 1 ? (showMore = 1) : (showMore = 0)"
                >Leer más</v-btn
              >
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" xl="5">
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            class="matriceria-service d-flex right-up"
          >
            <div class="service-item-img-wrapper d-none d-md-block">
              <picture class="img-service">
                <source
                  srcset="@/assets/matriceria-service.webp"
                  type="image/webp"
                />
                <source
                  srcset="@/assets/matriceria-service.png"
                  type="image/png"
                />
                <img
                  class="img-service"
                  src="@/assets/matriceria-service.png"
                  alt="Moldes de envases para soplado e inyección"
                />
              </picture>
            </div>
            <div class="service-item-content px-5">
              <h3 class="title-service mb-3 d-none d-md-block">MATRICERÍA</h3>
              <h4 class="subtitle-service mb-3 d-none d-md-block">
                Fabricación de moldes de soplado e inyección, precisos y
                personalizados de alta calidad.
              </h4>
              <div :class="['desc pb-3', { 'show-more': showMore === 2 }]">
                <picture class="img-service d-md-none">
                  <source
                    srcset="@/assets/matriceria-service.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="@/assets/matriceria-service.png"
                    type="image/png"
                  />
                  <img
                    class="img-service d-md-none"
                    src="@/assets/matriceria-service.png"
                    alt="Moldes de envases para soplado e inyección"
                  />
                </picture>

                <h3 class="title-service mb-3 d-md-none">MATRICE&shy;RÍA</h3>
                <h4 class="subtitle-service mb-3 d-md-none">
                  Fabricación de moldes de soplado e inyección, precisos y
                  personalizados de alta calidad.
                </h4>
                <p class="text-service">
                  Contamos con una infraestructura de última generación en el
                  área de matricería que nos permite la fabricación de moldes de
                  soplado e inyección con diversas características y tamaño.
                  Mantenemos siempre un trabajo de precisión y una excelente
                  calidad de nuestros moldes; lo cual garantiza reducción de
                  tiempo y costo en la producción de envases.
                </p>
              </div>
              <!--Boton de "leer mas" Matriceria-->
              <v-btn
                text
                class="btn-read-more d-none d-md-inline-block"
                @click="showMore !== 2 ? (showMore = 2) : (showMore = 0)"
                >Leer más</v-btn
              >
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" xl="5">
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            class="moldes-services left-up d-flex"
          >
            <div class="service-item-img-wrapper d-none d-md-block">
              <picture class="img-service">
                <source
                  srcset="@/assets/moldes-service.webp"
                  type="image/webp"
                />
                <source srcset="@/assets/moldes-service.png" type="image/png" />
                <img
                  class="img-service"
                  src="@/assets/moldes-service.png"
                  alt="Servicios de mantenimiento y reparación de moldes"
                />
              </picture>
            </div>
            <div class="service-item-content px-5">
              <h3 class="title-service mb-3 d-none d-md-block">
                SERVICIOS DE MANTENIMIENTO Y REPARACIÓN DE MOLDES.
              </h3>
              <div class="pb-3">
                <picture class="img-service d-md-none">
                  <source
                    srcset="@/assets/moldes-service.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="@/assets/moldes-service.png"
                    type="image/png"
                  />
                  <img
                    class="img-service d-md-none"
                    src="@/assets/moldes-service.png"
                    alt="Servicios de mantenimiento y reparación de moldes"
                  />
                </picture>

                <h3 class="title-service mb-3 d-md-none">
                  SERVICIOS DE MANTENI&shy;MIENTO Y REPARA&shy;CIÓN DE MOLDES.
                </h3>

                <p class="ma-0">Pulidos.</p>
                <p class="ma-0">Grabados.</p>
                <p class="ma-0">Torno.</p>
                <p class="ma-0">Fresa.</p>
                <p class="ma-0">Rectificadora.</p>
                <p class="ma-0">Electroerosión.</p>
                <p class="ma-0">Máquinas CNC.</p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" xl="5">
          <div
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            class="produccion-service right-up d-flex"
          >
            <div class="service-item-img-wrapper d-none d-md-block">
              <picture class="img-service">
                <source
                  srcset="@/assets/produccion-service.webp"
                  type="image/webp"
                />
                <source
                  srcset="@/assets/produccion-service.png"
                  type="image/png"
                />
                <img
                  class="img-service"
                  src="@/assets/produccion-service.png"
                  alt="Producción de envases plásticos"
                />
              </picture>
            </div>
            <div class="service-item-content px-5">
              <h3 class="title-service mb-3 d-none d-md-block">PRODUCCIÓN</h3>
              <div :class="['desc pb-3', { 'show-more': showMore === 4 }]">
                <picture class="img-service d-md-none d-block">
                  <source
                    srcset="@/assets/produccion-service.webp"
                    type="image/webp"
                  />
                  <source
                    srcset="@/assets/produccion-service.png"
                    type="image/png"
                  />
                  <img
                    class="img-service d-md-none d-block"
                    src="@/assets/produccion-service.png"
                    alt="Producción de envases plásticos"
                  />
                </picture>

                <h3 class="title-service mb-3 d-md-none">PRODUC&shy;CIÓN</h3>

                <p class="ma-0">
                  &bull; Servicios de maquila de envases y tapas plásticas con
                  moldes y/o materia prima suministrada por los clientes.
                </p>
                <p class="ma-0">
                  &bull; Asesoría técnica altamente calificada, sin costo
                  adicional, en la selección de los envases para cada industria
                  según el contenido a envasar.
                </p>
                <p class="ma-0">
                  &bull; Reparación y mantenimiento de moldes de soplado e
                  inyección a clientes con moldes propios.
                </p>
              </div>
              <!--Boton de "leer mas" Produccion-->
              <v-btn
                text
                class="btn-read-more d-none d-md-block"
                @click="showMore !== 4 ? (showMore = 4) : (showMore = 0)"
                >Leer más</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="container-services"> </v-container>
  </section>
</template>

<script>
export default {
  name: 'Servicios',
  props: {
    onIntersect: Function
  },
  data() {
    return {
      showMore: 0,
      servicios1IsIntersecting: false
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.servicios {
  padding-top: 40px;
}
.title-section {
  text-align: center;
  font-weight: bold;
  font-size: min(max(2rem, 4vw), 3.75rem);
  @supports (font-size: clamp(2rem, 4vw, 3.75rem)) {
    font-size: clamp(2rem, 4vw, 3.75rem);
  }
  position: relative;
  display: inline-block;
  margin: 0 auto;
  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: -10px;
    width: 50%;
    height: 4px;
    background: $primary;
    left: 50%;
    transform: translateX(-50%);
  }
}
.title-service {
  color: #005eb1;
  font-size: min(max(1rem, 4vw), 1.3rem);
  @supports (font-size: clamp(2rem, 4vw, 2rem)) {
    font-size: clamp(1rem, 4vw, 1.3rem);
  }
  line-height: 1.1;
}
.desc {
}
.service-item-img-wrapper {
}
.img-service {
  float: left;
  width: 140px;
  height: auto;
  margin-bottom: 5px;
  margin-right: 10px;
}
.clear {
  overflow: auto;
}
.clear::after {
  content: '';
  clear: both;
  display: table;
}

.left-up-enter-active,
.left-up-leave-active {
  transition: all 1000ms ease-out;
}

.left-up-enter, .slide-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translate(-10%, 10%);
}

// Responsive

// SM
@media only screen and (min-width: 600px) {
}
// MD
@media only screen and (min-width: 960px) {
  .servicios {
    padding-top: 80px;
  }
  .service-item-img-wrapper {
    flex: 0 0 150px;
  }
  .img-service {
    float: none;
    width: 100%;
    height: auto;
    margin-bottom: 0;
    margin-right: 0;
  }
  .desc {
    position: relative;
    overflow: hidden;
    max-height: 200px;
    transition: max-height 450ms ease-out;
    &.show-more {
      max-height: 1000px;
      &::after {
        background: none;
      }
    }
    &::after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%
      );
    }
  }
}
// LG
@media only screen and (min-width: 1264px) {
  .service-item-img-wrapper {
    flex: 0 0 250px;
  }
}
// XL
@media only screen and (min-width: 1904px) {
  .service-item-img-wrapper {
    flex: 0 0 350px;
  }
}
</style>
