var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"nosotros",attrs:{"id":"nosotros"}},[_c('div',{staticClass:"title-wrapper pa-8 mb-md-12"},[_c('h2',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.onIntersect,
        options: {
          threshold: [0, 0.5, 1.0]
        }
      }),expression:"{\n        handler: onIntersect,\n        options: {\n          threshold: [0, 0.5, 1.0]\n        }\n      }"}],staticClass:"title-nosotros fade"},[_vm._v(" Nosotros ")])]),_c('v-carousel',{directives:[{name:"intersect",rawName:"v-intersect",value:({
      handler: _vm.onIntersect,
      options: {
        threshold: [0, 0.5, 1.0]
      }
    }),expression:"{\n      handler: onIntersect,\n      options: {\n        threshold: [0, 0.5, 1.0]\n      }\n    }"}],staticClass:"d-md-none slide-left",attrs:{"hide-delimiters":"","cycle":"","show-arrows":false,"height":"260px"}},[_c('v-carousel-item',[_c('img',{staticClass:"img-nosotros d-block",attrs:{"src":require("@/assets/nosotros-envases.png"),"alt":"Envases de colores"}})]),_c('v-carousel-item',[_c('img',{staticClass:"img-nosotros d-block",attrs:{"src":require("@/assets/bg-hero-2.png"),"alt":"Envases de colores"}})]),_c('v-carousel-item',[_c('img',{staticClass:"img-nosotros d-block",attrs:{"src":require("@/assets/nosotros-2.png"),"alt":"Envases de colores"}})])],1),_c('v-container',{staticClass:"nosotros-container"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pr-8 mb-8",attrs:{"cols":"12","md":"6","lg":"5"}},[_c('v-carousel',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: {
              threshold: [0, 0.5, 1.0]
            }
          }),expression:"{\n            handler: onIntersect,\n            options: {\n              threshold: [0, 0.5, 1.0]\n            }\n          }"}],staticClass:"d-none d-md-block slide-left",attrs:{"hide-delimiters":"","cycle":"","show-arrows":false,"height":"400px"}},[_c('v-carousel-item',[_c('picture',{staticClass:"img-nosotros d-block"},[_c('source',{attrs:{"srcset":require("@/assets/nosotros-envases.webp"),"type":"image/webp"}}),_c('source',{attrs:{"srcset":require("@/assets/nosotros-envases.png"),"type":"image/png"}}),_c('img',{staticClass:"img-nosotros d-block",attrs:{"src":require("@/assets/nosotros-envases.png"),"alt":"Envases de colores"}})])]),_c('v-carousel-item',[_c('picture',{staticClass:"img-nosotros d-block"},[_c('source',{attrs:{"srcset":require("@/assets/bg-hero-2.webp"),"type":"image/webp"}}),_c('source',{attrs:{"srcset":require("@/assets/bg-hero-2.png"),"type":"image/png"}}),_c('img',{staticClass:"img-nosotros d-block",attrs:{"src":require("@/assets/bg-hero-2.png"),"alt":"Envases de colores"}})])]),_c('v-carousel-item',[_c('picture',{staticClass:"img-nosotros d-block"},[_c('source',{attrs:{"srcset":require("@/assets/nosotros-2-2.webp"),"type":"image/webp"}}),_c('source',{attrs:{"srcset":require("@/assets/nosotros-2-2.png"),"type":"image/png"}}),_c('img',{staticClass:"img-nosotros d-block",attrs:{"src":require("@/assets/nosotros-2-2.png"),"alt":"Envases de colores"}})])])],1)],1),_c('v-col',{staticClass:"pr-8 pl-8 mb-8",attrs:{"cols":"12","md":"6","lg":"5"}},[_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: {
              threshold: [0, 0.5, 1.0]
            }
          }),expression:"{\n            handler: onIntersect,\n            options: {\n              threshold: [0, 0.5, 1.0]\n            }\n          }"}],staticClass:"text-about-us slide-right"},[_c('img',{staticClass:"logo-nosotros d-block mb-10",attrs:{"width":"343px","src":require("@/assets/logo-nosotros.png"),"alt":"logo de Herama Group"}}),_c('h3',{staticClass:"subtitle-description mb-4"},[_vm._v(" Más de 20 Años de experiencia. ")]),_c('p',{staticClass:"text-about"},[_vm._v(" Somos una empresa permanentemente renovada en cuanto a su infraestructura y capacidad instalada, así como por la capacitación firme y constante a nuestro personal, para proporcionar una excelente atención y asesoría a nuestros clientes. ")])])]),_c('v-col',{staticClass:"pr-8 pl-8 mb-8",attrs:{"cols":"12","md":"6","lg":"5"}},[_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: {
              threshold: [0, 0.5, 1.0]
            }
          }),expression:"{\n            handler: onIntersect,\n            options: {\n              threshold: [0, 0.5, 1.0]\n            }\n          }"}],staticClass:"mision-description slide-left"},[_c('h3',{staticClass:"title-mision mb-4"},[_vm._v("Nuestra misión")]),_c('p',{staticClass:"text-about"},[_vm._v(" Distribuir eficaz y eficientemente envases para satisfacer las necesidades actuales y futuras del mercado, apoyando a los clientes en la búsqueda del éxito de sus productos. A su vez apoyando el sector industrial con el servicio de asesoría y provisión de la maquinaria necesaria para iniciar o repotenciar su empresa en el sector plástico. ")])])]),_c('v-col',{staticClass:" pr-8 pl-8 mb-8",attrs:{"cols":"12","md":"6","lg":"5"}},[_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: {
              threshold: [0, 0.5, 1.0]
            }
          }),expression:"{\n            handler: onIntersect,\n            options: {\n              threshold: [0, 0.5, 1.0]\n            }\n          }"}],staticClass:"vision-description slide-right"},[_c('h3',{staticClass:"title-mision mb-4"},[_vm._v("Nuestra visión")]),_c('p',{staticClass:"text-about"},[_vm._v(" Herama es una empresa dedicada a servir a los mercados nacionales y latinoamericanos, respetuosa y comprometida con sus clientes, empleados y la comunidad. Llevándoles productos de alta eficiencia en pro del crecimiento industrial de los países. ")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }