<template>
  <footer class="pt-5 pt-md-8 pt-lg-16">
    <v-container class="footer-container">
      <v-row justify="space-around">
        <!--Logos de Herama y redes sociales-->

        <v-col
          cols="12"
          md="3"
          lg="3"
          class="order-2 order-md-1 first-column d-flex justify-space-between aling-center flex-md-column justify-md-center align-md-end"
        >
          <div
            class="logo-footter-content-wrapper d-flex justify-center align-center flex-column"
          >
            <div class="logo-white-footer-wrapper mb-md-8 ">
              <img
                class="logo-white-footer"
                src="@/assets/logo-main.png"
                alt="Logo de Herama Group version blanco"
              />
            </div>
            <div class="logos-redes-white d-flex align-center">
              <!-- <img
                class="logo-fb-white mr-3"
                src="@/assets/logo-fb-white.png"
                alt="Logo de Facebook blanco"
              />
              <img
                class="logo-tw-white mr-3"
                src="@/assets/logo-tw-white.png"
                alt="Logo de Twitter blanco"
              /> -->
              <a href="https://wa.me/50768367760" target="_blank" class="mr-3">
                <img
                  class=""
                  src="@/assets/logo-whatsapp-white.png"
                  alt="Logo de whatsapp blanco"
                />
              </a>
              <a
                href="https://www.instagram.com/heramaplastic/"
                target="_blank"
              >
                <img
                  class="logo-ig-white"
                  src="@/assets/logo-ig-white.png"
                  alt="Logo de Instagram blanco"
                />
              </a>
            </div>
          </div>
        </v-col>
        <v-col cols="1" class="order-md-2 d-none d-md-block">
          <div class="separador"></div>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class=" order-3 order-md-3 d-flex flex-column justify-md-center"
        >
          <div class="info-empresa d-flex flex-column">
            <p class="direction pr-2">
              Herama Plastic, INC. Ruc. 155655075-2-2017-DV 40 Urbanización
              Industrial Anasa, No. 43365, Bodega 9, Juan Díaz, Ciudad de
              Panamá, Panamá.
            </p>
            <div class="info-empresa-second d-flex flex-column">
              <div class="d-flex align-center">
                <img
                  class="icon-phone d-none d-md-block"
                  src="@/assets/icon-phone.png"
                  alt="Icono de telefono"
                />
                <p class="contact-info mb-0 pl-2">
                  Oficina: +507-373-3848
                </p>
              </div>
              <div class="d-flex align-center">
                <img
                  class="icon-phone d-none d-md-block"
                  src="@/assets/celular-logo-white.png"
                  alt=""
                />
                <p class="contact-info mb-0 pl-2">
                  Celular: +507-6595-0167
                </p>
              </div>
              <div class="d-flex align-center">
                <img
                  class="icon-phone d-none d-md-block"
                  src="@/assets/wa-logo-white.png"
                  alt=""
                />
                <p class="contact-info mb-0 pl-2">
                  WhatsApp: +507-6595-0167
                </p>
              </div>
              <div class="d-flex align-center">
                <img
                  class="icon-phone d-none d-md-block"
                  src="@/assets/ig-logo-white.png"
                  alt=""
                />
                <p class="contact-info mb-0 pl-2">
                  Instagram: @heramaplastic
                </p>
              </div>
              <div class="d-flex align-center">
                <img
                  class="icon-phone d-none d-md-block"
                  src="@/assets/email-logo-white.png"
                  alt=""
                />
                <p class="contact-info mb-0 pl-2">
                  Correo: heramaplastic@gmail.com
                </p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="1" class="order-md-4 d-none d-md-block"> </v-col>
        <v-col
          cols="12"
          md="3"
          class=" order-1 order-md-5 d-flex flex-column justify-center mb-5"
        >
          <ContactForm />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-center">
          <p class="copy-dawson">Copyright &copy; Herama Plastic, INC.</p>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
import ContactForm from './ContactForm'
export default {
  name: 'MyFooter',
  components: {
    ContactForm
  },
  props: {
    onIntersect: Function
  }
}
</script>

<style lang="scss" scoped>
.no-webp footer {
  background-image: url('~@/assets/Footer.png');
}
.webp footer {
  background-image: url('~@/assets/Footer.webp');
}
footer {
  background-size: cover;
  background-position: center;
  color: #fff;
  .footer-container {
    max-width: 400px;
  }
  .separador {
    height: 100%;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      background-color: #fff;
      width: 2px;
      height: 50%;
      top: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.7;
    }
  }
  .logo-white-footer-wrapper {
    max-width: 128px;
    img {
      width: 100%;
    }
  }
  .logos-redes-white {
    img {
      max-width: 20px;
      height: auto;
    }
  }
  .info-empresa {
    width: 100%;
    font-size: 0.8rem;
  }
  .direction {
    flex: 1 1 50%;
  }
  .info-empresa-second {
    flex: 1 1 50%;
  }
}
// Responsive

// SM
@media only screen and (min-width: 600px) {
}
// MD
@media only screen and (min-width: 960px) {
  footer {
    .footer-container {
      max-width: 900px;
    }
    .logo-white-footer-wrapper {
      max-width: 258px;
    }
    .logos-redes-white {
      img {
        max-width: 30px;
        height: auto;
      }
    }
    .info-empresa {
      width: 100%;
      font-size: 1rem;
    }
    .direction {
      flex: 1 1 50%;
    }
    .info-empresa-second {
      flex: 1 1 50%;
    }
  }
}
// LG
@media only screen and (min-width: 1264px) {
  footer {
    .footer-container {
      max-width: 1185px;
    }
  }
}
// XL
@media only screen and (min-width: 1904px) {
  footer {
    .footer-container {
      max-width: 1785px;
    }
  }
}
</style>
