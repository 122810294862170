import { render, staticRenderFns } from "./ScrollingMenu.vue?vue&type=template&id=10ccb2b8&scoped=true&"
import script from "./ScrollingMenu.vue?vue&type=script&lang=js&"
export * from "./ScrollingMenu.vue?vue&type=script&lang=js&"
import style0 from "./ScrollingMenu.vue?vue&type=style&index=0&id=10ccb2b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10ccb2b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VContainer,VList,VListItem,VListItemTitle,VMenu})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
