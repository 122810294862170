import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { firebaseConfig } from './firebaseConfig'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

firebase.initializeApp(firebaseConfig)
// firebase.functions().useEmulator('localhost', 5001)

export const db = firebase.firestore()
export const functions = firebase.functions()

import vuetify from './plugins/vuetify'
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, {
  siteKey: '6LfJg10aAAAAAFWs_p91Nsuk9qm5AJUt8MIH9n5l',
  loaderOptions: {
    autoHideBadge: true
  }
})

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
