<template>
  <div class="form-contact-footer">
    <h3
      :class="[
        'form-title-foot mb-5',
        { 'text-center': modal, 'mb-10': modal, 'red--text': haveError }
      ]"
    >
      {{ msgToUser }}
      <span v-if="success" :style="modal ? 'color: #0c8a08;' : '#fff'"
        >&#10003;</span
      >
    </h3>

    <v-form ref="contactForm" @submit.prevent>
      <div class="form-content">
        <v-text-field
          v-model="name"
          label="Nombre"
          solo
          dense
          :rules="rules"
          :rounded="modal"
          :flat="!modal"
          :disabled="sending"
        ></v-text-field>
        <v-text-field
          v-model="email"
          label="Email"
          type="email"
          solo
          dense
          :rules="emailRules"
          :rounded="modal"
          :flat="!modal"
          :disabled="sending"
        ></v-text-field>
        <v-text-field
          v-model="company"
          label="Compañia"
          solo
          dense
          :rounded="modal"
          :flat="!modal"
          :disabled="sending"
        ></v-text-field>
        <v-textarea
          v-model="message"
          label="Mensaje"
          solo
          dense
          class="textarea"
          :rules="rules"
          :rounded="modal"
          :flat="!modal"
          rows="3"
          :disabled="sending"
        ></v-textarea>
      </div>
      <v-btn
        rounded
        color="primary"
        depressed
        :block="modal"
        class="d-block ml-auto"
        :loading="sending"
        @click.prevent="handleSend"
        >Enviar</v-btn
      >
    </v-form>
    <div v-if="!modal" class="recaptcha-info">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a>
      apply.
    </div>
  </div>
</template>

<script>
import { functions } from '../main.js'
export default {
  name: 'ContactForm',
  props: {
    modal: Boolean
  },
  data() {
    return {
      msgToUser: '¿Cómo podemos ayudarle?',
      haveError: false,
      success: false,
      name: '',
      email: '',
      message: '',
      company: '',
      sending: false,
      emailRules: [
        v => !!v || 'Este campo es requerido',
        v => /.+@.+\..+/.test(v) || 'Se requiere un email válido'
      ],
      rules: [v => !!v || 'Este campo es requerido']
    }
  },

  methods: {
    async handleSend() {
      if (!this.$refs.contactForm.validate()) {
        return
      }

      await this.$recaptchaLoaded().catch(error => {
        // eslint-disable-next-line
        console.log('error: ', error)
      })

      // Execute reCAPTCHA with action "contact".
      const token = await this.$recaptcha('contact').catch(error => {
        // eslint-disable-next-line
        console.log('error: ', error)
      })
      if (!token) {
        // eslint-disable-next-line
        console.log('no token')

        return
      }
      this.sending = true
      this.msgToUser = 'Enviando, por favor espere'

      const handleLeads = functions.httpsCallable('handleLeads')
      const data = {
        name: this.name,
        email: this.email,
        message: this.message,
        company: this.company,
        recaptchaToken: token
      }
      // eslint-disable-next-line
      console.log('data: ', data)

      handleLeads(data)
        .then(result => {
          // eslint-disable-next-line
          console.log('result: ', result)

          this.sending = false
          this.msgToUser = '¡Su mensaje ha sido enviado!'
          this.success = true
          this.name = ''
          this.email = ''
          this.message = ''
          this.company = ''
          this.$refs.contactForm.reset()
          setTimeout(() => {
            if (this.modal) {
              this.$emit('close-modal')
            }
            this.success = false
            this.msgToUser = '¿Cómo podemos ayudarle?'
          }, 5000)
        })
        .catch(error => {
          this.sending = false
          this.msgToUser =
            'Hubo un error y no pudimos enviar el mensaje, por favor intenta más tarde'
          this.haveError = true
          // eslint-disable-next-line
          console.log('error: ', error)

          setTimeout(() => {
            if (this.modal) {
              this.$emit('close-modal')
            }
            this.msgToUser = '¿Cómo podemos ayudarle?'
            this.haveError = false
          }, 5000)
        })
    }
  }
}
</script>

<style lang="scss">
.recaptcha-info {
  margin-top: 10px;
  color: #fff;
  font: 0.65rem;
  opacity: 0.75;
  &.modal {
    color: rgb(96, 96, 96);
  }
}
textarea {
  resize: none !important;
}
</style>
