var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"porque-trabajar-nosotros py-8 py-md-16 px-5 "},[_c('v-container',{},[_c('h2',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.onIntersect,
        options: {
          threshold: [0, 0.5, 1.0]
        }
      }),expression:"{\n        handler: onIntersect,\n        options: {\n          threshold: [0, 0.5, 1.0]\n        }\n      }"}],staticClass:"title-trabaja-nosotros center-up mb-16"},[_vm._v(" ¿Por qué trabajar con nosotros? ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: {
              threshold: [0, 0.5, 1.0]
            }
          }),expression:"{\n            handler: onIntersect,\n            options: {\n              threshold: [0, 0.5, 1.0]\n            }\n          }"}],staticClass:"experiencia left-up"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3","md":"12"}},[_c('div',{staticClass:"img-wrapper"},[_c('img',{staticClass:"icon-trabaja-nosotros",attrs:{"src":require("@/assets/experiencia-icon.png"),"alt":"Icono descripción de experiencia"}})])]),_c('v-col',{attrs:{"cols":"9","md":"12"}},[_c('div',{staticClass:"content px-4 text-md-center"},[_c('h3',{staticClass:"title mb-3"},[_vm._v("Experiencia")]),_c('p',{staticClass:"description-text"},[_vm._v(" Hablar de más de "),_c('strong',[_vm._v("20 años")]),_vm._v(" de experiencia en el mercado nos otorga la "),_c('strong',[_vm._v("credibilidad")]),_vm._v(" necesaria para ofrecerle la mejor "),_c('strong',[_vm._v("confiabilidad")]),_vm._v(" en nuestra gestión de servicio. ")])])])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: {
              threshold: [0, 0.5, 1.0]
            }
          }),expression:"{\n            handler: onIntersect,\n            options: {\n              threshold: [0, 0.5, 1.0]\n            }\n          }"}],staticClass:"profesionalismo center-up"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"12"}},[_c('div',{staticClass:"img-wrapper"},[_c('img',{staticClass:"icon-trabaja-nosotros",attrs:{"src":require("@/assets/profesionalismo-icon.png"),"alt":"Icono descripción de profesionalismo"}})])]),_c('v-col',{attrs:{"cols":"9","md":"12"}},[_c('div',{staticClass:"content px-4 text-md-center"},[_c('h3',{staticClass:"title"},[_vm._v("Profesionalismo")]),_c('p',{staticClass:"description-text"},[_vm._v(" Hacer las cosas de manera correcta y conocer a cabalidad nuestro "),_c('strong',[_vm._v("desempeño")]),_vm._v(" nos ha potenciado para exigirnos a nosotros mismos en la preparación y manufactura de nuestros productos. ")])])])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: {
              threshold: [0, 0.5, 1.0]
            }
          }),expression:"{\n            handler: onIntersect,\n            options: {\n              threshold: [0, 0.5, 1.0]\n            }\n          }"}],staticClass:"calidad right-up"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3","md":"12"}},[_c('div',{staticClass:"img-wrapper"},[_c('img',{staticClass:"icon-trabaja-nosotros",attrs:{"src":require("@/assets/calidad-icon.png"),"alt":"Icono descripción de Calidad"}})])]),_c('v-col',{attrs:{"cols":"9","md":"12"}},[_c('div',{staticClass:"content px-4 text-md-center"},[_c('h3',{staticClass:"calidad-title"},[_vm._v("Calidad")]),_c('p',{staticClass:"description-text"},[_vm._v(" Es un valor presente en todos nuestros procesos de fabricación para "),_c('strong',[_vm._v("garantizarle")]),_vm._v(" que usted reciba la mejor atención y el mejor producto. ")])])])],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }