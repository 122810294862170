<template>
  <transition appear="" name="slide-down">
    <div v-if="show" class="scrolling-menu ">
      <v-container class="py-0">
        <div class="nav-bar d-flex align-center justify-space-between">
          <div class="logo-wrapper">
            <img class="d-block" src="@/assets/logo-main.png" alt="" />
          </div>
          <div
            :class="['menu-btn d-md-none', { open: openMenu }]"
            role="button"
            @click.stop="openMenu = !openMenu"
          >
            <div class="line line1"></div>
            <div class="line line2"></div>
            <div class="line line3"></div>
          </div>

          <nav
            v-click-outside="{
              handler: hanldeClickOutside,
              include: include
            }"
            :class="['navbar-nav', { open: openMenu }]"
          >
            <ul
              class="nav-links d-flex flex-column flex-md-row justify-center justify-md-end align-md-center"
            >
              <li
                v-for="link in links"
                :key="link.title"
                :class="{ active: link.active }"
              >
                <a
                  :class="link.img ? 'home-link d-block ml-auto' : ''"
                  @click="handleNavigation(`#${link.title}`)"
                >
                  <img v-if="link.img" class="" :src="link.img" alt="" />
                  <span v-else>{{
                    link.title.charAt(0).toUpperCase() +
                      link.title.slice(1).toLowerCase()
                  }}</span>
                </a>
              </li>

              <li>
                <v-menu bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <span color="primary" dark v-bind="attrs" v-on="on">
                      Línea de negocios
                    </span>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title>Herama Machinery</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Herama Food</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Herama Home</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Herama Liquor</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </li>
            </ul>
          </nav>
        </div>
      </v-container>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ScrollingMenu',
  props: {
    show: Boolean,
    openMenuExternal: Boolean,
    active: String,
    links: Array
  },
  data() {
    return {
      openMenu: false
    }
  },
  watch: {
    openMenuExternal(val) {
      this.openMenu = val
    },
    active(val) {
      console.log(this.$refs[val])
    }
  },
  methods: {
    hanldeClickOutside() {
      if (this.openMenu) {
        this.openMenu = false
      }
    },
    handleNavigation(goTo) {
      this.openMenu = false
      const offset = goTo === '#nosotros' ? 60 : 40
      this.$vuetify.goTo(goTo, { offset })
    },
    include() {
      return [document.querySelector('.menu-btn')]
    }
  }
}
</script>

<style lang="scss" scoped>
.scrolling-menu {
  position: fixed;
  background-color: #0044bc;
  color: #fff;
  z-index: 999;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;

  .logo-wrapper {
    width: 115px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .navbar-nav {
    background-color: #0044bc;
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 100vh;
    transform: translate(100%);
    transition: transform 250ms ease-out;
    &.open {
      transform: translateX(0);
    }
  }
  ul {
    list-style: none;
    text-align: right;
    font-size: 1.5rem;
    height: 100%;
    padding: 0;
    li {
      padding: 15px;
      transition: background-color 250ms ease-out;
      &.active {
        background-color: $primary;
      }
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  .menu-btn {
    position: relative;
    z-index: 9999;
  }
  .home-link {
    width: 22px;
    img {
      width: 100%;
      height: auto;
      vertical-align: text-bottom;
    }
  }
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 250ms ease-out;
}

.slide-down-enter, .slide-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-100%);
}

// Responsive

// SM
@media only screen and (min-width: 600px) {
}
// MD
@media only screen and (min-width: 960px) {
  .scrolling-menu {
    .logo-wrapper {
      width: 200px;
    }
    .navbar-nav {
      position: relative;
      transform: translate(0);
      height: auto;
    }
    ul {
      padding-right: 0;
      font-size: 1.1rem;
      height: 100%;
      li {
        padding: 20px 15px;
        height: 100%;
        &:last-child {
          margin-right: 0;
          padding-right: 0;
        }
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
// LG
@media only screen and (min-width: 1264px) {
}
// XL
@media only screen and (min-width: 1904px) {
}
</style>
